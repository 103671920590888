import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"

const NotFoundPage = ({ location }) => (
  <Layout location={location}>
    <section className="not-found">
      <div className="container">
        <h2>Страница не найдена</h2>
        <p>
          К сожалению страница не найден. Вернуться на{" "}
          <Link to="/">главную</Link>?
        </p>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
